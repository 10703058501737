
export const scattercontent={
    Abstract:['人体右心房上有一个特殊的小结节，由特殊的细胞构成，叫做窦房结。它可以自动地、有节律地产生电流，电流按传导组织的顺序传送到心脏的各个部位，从而引起心肌细胞的收缩和舒张。人体正常的心跳就是从这里发出的，这就是“心脏起搏点”。窦房结每发生1次冲动，心脏就跳动1次，凡是由窦房结发出激动所形成的心律总称为窦性心律。',
    '室上性早搏是心室以上出现的过早的搏动，比如房性早搏，或是交界性的早搏。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0房性早搏，即房性期前收缩，起源于窦房结以外心房的任何部位。正常人进行24小时心电检测，约60%的人有房性期前收缩发生。各种器质性心脏病患者均可发生房性期前收缩，并经常是快速性房性心律失常出现的先兆。主要症状为心悸、心脏“停跳”感，期前收缩次数过多时自觉“心跳很乱”，可有胸闷、心前区不适、头昏、乏力、脉搏有间歇等。也有无症状者。可能因期前收缩持续时间较久，患者已适应。此外，期前收缩的症状与患者的精神状态有密切关系，不少患者的很多症状是由于对期前收缩不正确的理解和恐惧、焦虑等情绪所致。',
    '室上性早搏是心室以上出现的过早的搏动，比如房性早搏，或是交界性的早搏。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0房性早搏，即房性期前收缩，起源于窦房结以外心房的任何部位。正常人进行24小时心电检测，约60%的人有房性期前收缩发生。各种器质性心脏病患者均可发生房性期前收缩，并经常是快速性房性心律失常出现的先兆。主要症状为心悸、心脏“停跳”感，期前收缩次数过多时自觉“心跳很乱”，可有胸闷、心前区不适、头昏、乏力、脉搏有间歇等。也有无症状者。可能因期前收缩持续时间较久，患者已适应。此外，期前收缩的症状与患者的精神状态有密切关系，不少患者的很多症状是由于对期前收缩不正确的理解和恐惧、焦虑等情绪所致。',
    '室性早搏或室性期前收缩，简称室早，是临床上非常常见的心律失常，是最常见的一种期前收缩。其发生群相当广泛，包括正常健康人群和各种心脏病患者。室早是指窦房结激动尚未抵达心室之前，心室内某一异位兴奋灶提前激动而引起心室搏动。室早能引起心悸及颈部血管搏动，多源复杂的室早会加重原有的心脏病症状；急性心肌缺血时，某些类型的室早会诱发室速、室颤。合理的选用抗心律失常药物控制室早是很重要的。',
    '室性早搏或室性期前收缩，简称室早，是临床上非常常见的心律失常，是最常见的一种期前收缩。其发生群相当广泛，包括正常健康人群和各种心脏病患者。室早是指窦房结激动尚未抵达心室之前，心室内某一异位兴奋灶提前激动而引起心室搏动。室早能引起心悸及颈部血管搏动，多源复杂的室早会加重原有的心脏病症状；急性心肌缺血时，某些类型的室早会诱发室速、室颤。合理的选用抗心律失常药物控制室早是很重要的。',
    '室性早搏或室性期前收缩，简称室早，是临床上非常常见的心律失常，是最常见的一种期前收缩。其发生群相当广泛，包括正常健康人群和各种心脏病患者。室早是指窦房结激动尚未抵达心室之前，心室内某一异位兴奋灶提前激动而引起心室搏动。室早能引起心悸及颈部血管搏动，多源复杂的室早会加重原有的心脏病症状；急性心肌缺血时，某些类型的室早会诱发室速、室颤。合理的选用抗心律失常药物控制室早是很重要的。',
    '窦性心律慢于每分钟60次称为窦性心动过缓。可见于健康的成人，尤其是运动员、老年人和睡眠时。其他原因为颅内压增高、血钾过高、甲状腺机能减退、低温以及用洋地黄、β受体阻滞剂、利血平、胍乙啶、甲基多巴等药物。在器质性心脏病中，窦性心动过缓可见。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0窦性心律过缓如心律不低于每分钟50次，一般无症状。如心律低于每分钟40次时常可引起心绞痛、心功能不全或晕厥等症状。',
    '窦性心动过速是最常见的一种心动过速，其发生常与交感神经兴奋及迷走神经张力降低有关。它不是一种原发性心律失常，可由多种原因引起。生理状态下可因运动、焦虑、情绪激动引起，也可发生在应用肾上腺素、异丙肾上腺素等药物之后。在发热、血容量不足、贫血、甲亢、呼吸功能不全、低氧血症、低钾血症、心衰等其他心脏疾患时极易发生。该病在控制原发病变或诱发因素后便可治愈，但易复发。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0频率是诊断窦性心动过速的一项标准，不同年龄组人群的基础心律是不一致的，特别是小儿心律本身就快于成人，因此，应参考年龄段诊断窦性心动过速。成年人当由窦房结所控制的心律其频率超过每分钟100次时为窦性心动过速，心率多在100—160次／分之间。',
    '窦性心律不齐是心律失常的一种。可以在正常人出现，也可以在心脏病人身上出现。具体说是心脏跳动的不整齐，就是每次心跳的间隔不一样，一般情况下心跳的间隔是一样的。正常人的这种不整齐常会伴有心跳慢于60次/分。窦性心律的起源未变，但节律不整，在同一导联上P-P间期差异>0.12s称窦性心律不齐，常与窦性心动过缓同时存在。较常见的一类心律不齐与呼吸周期有关，称呼吸性窦性心律不齐，多见于青少年，一般无临床意义。另有一些比较少见的窦性心律不齐与呼吸无关，如与心室收缩排血有关的（室相性）窦性心律不齐以及窦房结内游走性心律不齐等。',
    '心动过速（tachycardia）是发生于窦房结、心房或房室交界区（简称：交界区）或心室的连续性快速性心律失常心动过速常由早搏引发，连续三次或三次以上的快速搏动，就被称为短阵性心动过速。心动过速可呈阵发和非阵发性，前者一般指短阵发生，后者一般指在一份常规心电图上心动过速呈持续状态。如果 24 小时动态心电图中心动过速始终持续，常被称为无休止性心动过速。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0室上性心动过速包括房室结折返、房室折返，以及房性、交界区性自律性增高引发的心动过速。房室结折返、房室折返，且房室结前向传导(顺向型)的心动过速表现为窄QRS波，但如果伴有束支阻滞则表现为宽QRS波。房室结折返、房室折返，且旁路前向传导(逆向型)的心动过速表现为宽QRS波。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0传出阻滞(exit block)，是指起搏点的激动由于某种原因不能通过与周围心肌 交界部位以激动心房或心室，从而使规则的心律突然出现一次或多次漏搏或传出延迟的现象。',
    '心动过速（tachycardia）是发生于窦房结、心房或房室交界区（简称：交界区）或心室的连续性快速性心律失常心动过速常由早搏引发，连续三次或三次以上的快速搏动，就被称为短阵性心动过速。心动过速可呈阵发和非阵发性，前者一般指短阵发生，后者一般指在一份常规心电图上心动过速呈持续状态。如果 24 小时动态心电图中心动过速始终持续，常被称为无休止性心动过速。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0室上性心动过速包括房室结折返、房室折返，以及房性、交界区性自律性增高引发的心动过速。房室结折返、房室折返，且房室结前向传导(顺向型)的心动过速表现为窄QRS波，但如果伴有束支阻滞则表现为宽QRS波。房室结折返、房室折返，且旁路前向传导(逆向型)的心动过速表现为宽QRS波。',
    '心脏内同时存在两个节律点各自独立地发放冲动，两个节律点竞相激动心房或（和）心室，这种双重心律称之为并行心律（parasystole）。一般情况下主导节律点为窦房结，另一个是异位节律点。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0与一般的室性早搏(联律间期恒定)相比,插入性室性并行心律的早搏联律间期虽然可以不等，但由于是在2个窦性心律的QRS波(R波)之中插入的室性心搏，使得联律间期的变化范围变小,只有适当时机出现的室性心搏才能插入。',
    '心脏内同时存在两个节律点各自独立地发放冲动，两个节律点竞相激动心房或（和）心室，这种双重心律称之为并行心律（parasystole）。一般情况下主导节律点为窦房结，另一个是异位节律点。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0房性并行心律时，如P或P’经过房室结1：1下传时，RR间期可表现有多种形式：①单发性房性并行心律，即提前出现的窄QRS波，呈室上性，联律间期不等，代偿间期不等；②房性并行心律,间断呈现房性早搏二联律；③房性并行心律主要表现为房性早搏二联律；④双源性房性并行心律；⑤不同节律下合并的房性并行心律；⑥房性并行心律出现房性早搏代偿间歇完全。',
    '心脏内同时存在两个节律点各自独立地发放冲动，两个节律点竞相激动心房或（和）心室，这种双重心律称之为并行心律（parasystole）。一般情况下主导节律点为窦房结，另一个是异位节律点。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0交界性并行心律的心电图诊断与室性并行心律一样需满足:①提前出现的交界性早搏，其联律间期不固定;②各早搏间期之间存在一个最大公约数;③可出现室性或房性融合波。从普通的片段心电图中的观察要满足上述3个条件，有时是困难的,况且大多数的交界性早搏又易与下位房性早搏混淆,特别是伴有交界性或房内传导阻滞时。心电散点图通过一类特殊的RR间期观察，就能发现这类RR间期的性质，从而方便、快捷诊断交界性并行心律。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0交界性并行心律时，RR间期可表现为多种形式:①单发的交界性并行心律，即提前出现的QRS波，其前有或无P’波，有P’波时，P’R<120ms；其联律间期不等，代偿间期完全；②交界性并行心律时，间断呈现二联律；③交界性并行心律主要以二联律的方式呈现；④交界性并行心律合并室性早搏。',
    '当房室传导系统的相对不应期与绝对不应期延长，造成房室间传导延缓与中断，出现心室漏搏时，称为二度房室传导阻滞。根据P-R间期变化及心室漏搏规律，分为二度Ⅰ型和二度Ⅱ型。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0发生二度Ⅰ型房室传导阻滞时，传导系统的相对不应期延长，引起缓慢传导且传导进行性减慢，最终落入绝对不应期，发生传导中断。即从二度传导阻滞开始，就有部分心房激动不能下传心室，引起心搏的脱落。',
    '当房室传导系统的相对不应期与绝对不应期延长，造成房室间传导延缓与中断，出现心室漏搏时，称为二度房室传导阻滞。根据P-R间期变化及心室漏搏规律，分为二度Ⅰ型和二度Ⅱ型。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0二度Ⅱ型房室传导阻滞的发生机制是房室传导系统的绝对不应期病理性延长（P波脱落），室上性冲动抵达房室传导系统后，面临2种抉择：遭遇绝对不应期而终止传导；恰遇应激期下传心室，整体表现为PR间期固定型房室传导中断。',
    '临床观察发现，二度窦房阻滞与二度房室阻滞的心电散点图没有明显差别，需要根据逆向回放心电图确定诊断。但发现，对于过去未曾得到注意的一种文氏传导：PR关系恒定，RR间期周期性逐渐缩短后突然延长。由于心电散点图的应用,发现这种窦房文氏现象在临床发生的几率很高。在心电图上这种文氏现象的规律性受到忽视，被归为窦性心律不齐，但在心电散点图上，普通的窦性心律不齐表现为代表窦性心律的棒球拍形吸引子，远端面积增大成“扩张形”，多数文氏传导的心电散点图的棒球拍不变形，而是如下图的图形。',
    '窦房传导阻滞简称窦房阻滞，系因窦房结周围组织病变，使窦房结发出的激动传出到达心房的时间延长或不能传出，导致心房心室停搏。窦房传导阻滞可暂时出现，也可持续存在或反复发作。窦房阻滞患者常无症状，也可有轻度心悸、乏力感以及“漏跳”，心脏听诊可发现心律不齐、心动过缓、“漏跳”（长间歇）。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0窦房传导阻滞分为一度、二度Ⅰ型、二度Ⅱ型和三度。对于I度窦房传导阻滞，心电图通常无法直接诊断。',
    '窦房传导阻滞简称窦房阻滞，系因窦房结周围组织病变，使窦房结发出的激动传出到达心房的时间延长或不能传出，导致心房心室停搏。窦房传导阻滞可暂时出现，也可持续存在或反复发作。窦房阻滞患者常无症状，也可有轻度心悸、乏力感以及“漏跳”，心脏听诊可发现心律不齐、心动过缓、“漏跳”（长间歇）。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0窦房传导阻滞分为一度、二度Ⅰ型、二度Ⅱ型和三度。二度Ⅱ型窦房传导阻滞常出现逸搏，也可合并房室阻滞，也可是病态窦房结综合征的一个表现。',
    '心脏的正常兴奋起源于窦房结,通过结间束传至房室结,在房室结通过延迟传导后,再传向房室束、左右束支和浦肯野纤维网，传导系统中的不同部位的P细胞或者浦肯野细胞的兴奋频率是不同的，正常情况下窦房结自发兴奋的频率最高,主导心脏节律，其他部位的P细胞或浦肯野细胞的兴奋性被抑制。当窦房结的兴奋性降低至下位细胞的频率时，下位的细胞即发放兴奋,激动整个心脏，于是发生了下位的异位搏动，即逸搏，逸搏的次数≥3次，即为逸搏心律。',
    '当窦性冲动传导受到抑制(如窦性心动过缓、窦房传导阻滞、窦性停搏时)房性潜在起搏点的自律性高于窦性起搏点或窦性冲动未能传出，则房性起搏点便可控制心房，产生房性逸搏(atrialescape)。当窦房结受抑制的因素消失，则又可出现窦性心律，房性逸搏消失。所以房性逸搏是一种生理性代偿机制。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0连续3个或3个以上的房性逸搏即构成房性逸搏心律。',
    '窦性停搏又称窦性静止、窦性间歇、窦性暂停等。窦性停搏是指窦房结在一个或多个心动周期中不产生冲动，以致不能激动心房或整个心脏，又称为窦性静止。青年人多由于强烈的迷走神经反射所致，常见于咽部受刺激、气管插管、按压颈动脉窦或眼球、应用洋地黄、硫酸奎尼丁等药物。有时炎症、缺血、损伤、退行性变等各种因素，损伤了窦房结的自律细胞，造成窦性停搏。频发的窦性停搏是一种严重的心律失常，是窦房结功能衰竭的表现，必须查清病因给予治疗，常需安装人工心脏起搏器。',
    '室性逸搏较少见。若心脏停搏较长时间后，房室交界区又不能及时发放激动(如发生传出阻滞,或因隐匿传导而影响了交界性逸搏的如期发生)时，就会出现室性逸搏或逸搏心律。',
    '窦房结功能完全正常，而房室结传导完全阻滞的患者安置了双腔起搏器(DDD或VDD)，心房电极会起感知作用，心室电极会起起搏作用。',
    '窦房结功能完全正常，而房室结传导完全阻滞的患者安置了双腔起搏器(DDD或VDD)，心房电极会起感知作用，心室电极会起起搏作用。并且由于心房电极的感知功能，当出现房性早搏时，心房电极亦会感知到房性早搏引起的心房搏动，因此相隔感知的PR间期后，心室电极发放起搏脉冲引起心室搏动。',
    '过早搏动简称早搏。是指异位起搏点发出的过早冲动引起的心脏搏动，为最常见的心律失常。可发生在窦性或异位性（如心房颤动）心律的基础上。可偶发或频发，可以不规则或规则地在每一个或每数个正常搏动后发生，形成二联律或联律性过早搏动。按起源部位可分为窦性、房性、房室交接处性和室性四种。其中以室性早搏最常见，其次是房性，结性较少见。窦性过早搏动罕见。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0逸搏是指当窦房结兴奋性降低或停搏时，隐性起搏点的舒张期除极有机会达到阈电位，从而发生激动，带动整个心脏。当窦房结的兴奋性降低至下位细胞的频率时，下位的细胞即发放兴奋,激动整个心脏，于是发生了下位的异位搏动，即逸搏，逸搏的次数≥3次，即为逸搏心律。',
    '室性心动过速（VT）是指发生在希氏束分叉以下的束支、心肌传导纤维、心室肌的快速性心律失常，Wellens将其定义为：频率超过100次/min，连续3个或3个以上的自发性室性电除极活动。室性心动过速可以起源于左心室及右心室，持续性发作时的频率常常超过100次/min，并可发生血流动力学状态的恶化，可能蜕变为室扑，室颤，导致心源性猝死，需要积极治疗。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0心动过速是位于45°线上的稳态吸引子图形，但在一份动态心电图数据中，室性心动过速一般表现以窦性心律为基础伴有阵发或反复阵发的室性心动过速，而很少见到无休止室性心动过速.在心房或交界区可见到无休止性室上性心动过速，但多数都伴有传出阻滞。',
    '心房颤动（AF）简称房颤，是一种仅次于早搏的常见而重要的室上性心律失常。是心房呈无序激动和无效收缩的房性节律，是由心房-主导折返环引起许多小折返环导致的房律紊乱，在老年人中十分常见。据统计，60岁以上人群中，房颤发生率为1％，并随着年龄而增加。可见于所有的器质性心脏病患者，在非器质性心脏病患者也可发生房颤，发病率高持续时间长，还可引起严重的并发症，如心力衰竭和动脉栓塞。导致病人残疾或病死率增加。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0交界性逸博是最常见的逸搏。房室交界区电生理结构极为复杂，自律性受控于窦房结而不显现，是潜在的起搏点。当窦性心律减慢且慢于房室交界区潜在起搏点的固有频率时，出现交界性逸搏。一方面，顺向传导至心室，产生与窦性或其他室上性节律相同的QRS波群；另一方面，逆向传导至心房，产生逆行P-波。连续3个或3个以上的交界性逸搏即构成交界性逸搏心律。',
    '心脏内同时存在两个节律点各自独立地发放冲动，两个节律点竞相激动心房或（和）心室，这种双重心律称之为并行心律（parasystole）。一般情况下主导节律点为窦房结，另一个是异位节律点。\n\xa0\xa0\xa0\xa0\xa0\xa0\xa0室性并行心律的RR序列表现有多种形式:①单纯性室性并行心律，即类室性早搏节律，提前出现的QRS波，其后有完全代偿间期;②插入性室性并行心律，即室性异位搏动插在2个窦性激动之间;③表现为二、三联律室性早搏的并行心律;④不同节律合并的室性并行心律。',
    '心房颤动（AF）简称房颤，是一种仅次于早搏的常见而重要的室上性心律失常。是心房呈无序激动和无效收缩的房性节律，是由心房-主导折返环引起许多小折返环导致的房律紊乱，在老年人中十分常见。据统计，60岁以上人群中，房颤发生率为1％，并随着年龄而增加。可见于所有的器质性心脏病患者，在非器质性心脏病患者也可发生房颤，发病率高持续时间长，还可引起严重的并发症，如心力衰竭和动脉栓塞。导致病人残疾或病死率增加。',
    '心房扑动是一种快速异位心律失常，发生于心房内的，冲动频率较房性心动过速更快的心律失常，心电图表现为p波消失，出现大小、形态、间距基本相同的F波。F波在Ⅱ、Ⅲ和aVF导联明显呈陡峭的上升支和缓慢的下降支。心房扑动实际是一种大折返性房性心动过速。心脏存在很多生理性或病理性解刨屏障，当一次电冲动围绕某个插入口或解刨结构快速的环形运动，即可发生心房扑动。'],
    Content:['正常窦性心电散点图位于45°线中段，呈棒球拍形的一分布稳态吸引子*图形。',
    '少数窦性心律伴室上性早搏的图形呈四分布，是因为早搏后点的D图未与A图重叠而分布在慢加速区。室上性早搏B图的长轴(B线)与X轴成夹角，斜率多在0.5左右。',
    '多数窦性心律伴室上性早搏的图形呈三分布，由位于45°线中段的窦律稳态吸引子(A图)、位于快加速区的早搏前点的非稳态吸引子(B图)和位于快减速区的早搏点非稳态吸引子(C图)组成,由于早搏后点的吸引子(D图)与A图重叠被其掩埋，只表现为三分布。',
    '多数窦性心律伴室性早搏是四分布图形，由位于45°线中段的窦律稳态吸引子(A图)、位于快加速区的早搏前点非稳态吸引子(B图)、位于快减速区的早搏点非稳态吸引子(C图)和位于慢加加速区的早搏后点吸引子(D图)组成。',
    '少数窦性心律伴室性早搏的图形呈三分布，为室性早搏三联律或发作性室性早搏二联律。',
    '如果室性早搏呈无休止二联律状态则呈二分布。室性早搏图形的B线与X轴平行，斜率多趋向零。',
    '窦性心动过缓的RR间期1000ms,相应的心率＜60次/分。心电散点图沿45°线分布，所对应的横、纵坐标点的数字在＞1000ms的位置，所在窦性心动过缓的吸引子图形位于45°线的远端。窦性心动过缓的心电散点图形与其心率变异性有关,如心率变异性正常，可呈棒球拍形或类棒球拍形,如心率变异性降低，可呈“收缩形”或小棒状，如伴有窦性心律不齐，呈“粗壮”的棒球拍形(“扩张形”)，也可呈较粗的棒状或其他形状。',
    '窦性心动过速的RR间期＜600ms,相应的心率＞100次/分。心电散点图是位于45°线近端稳态吸引子图形，所对应的横、纵坐标点的数字在＜600ms的位置。由于心动过速,RR间期相对整齐，图形面积变小呈“收缩的”棒球拍形，或不呈棒球拍形，而是呈比较细的棒状。',
    '窦性心律不齐主要表现瞬时RR间期变化增大，但平均心率可以正常。窦性心律不齐常与窦性心动过缓相伴，但窦性心律不齐很少与窦性心动过速相伴，相反，窦性心动过速时常表现心率变异的降低。窦性心律不齐的吸引子图形位于45°线的中段(正常窦律)或远端(窦性心动过缓时)，多呈“粗壮”的棒球拍形(“扩张形”)，越靠远端，膨大的越明显，也可呈较粗的棒状或其他形状。窦性心律不齐的心电散点图与正常窦性心律一样位于45°线的中段，但面积增大，呈“扩张”棒球拍形。',
    '持续性室上性心动过速的心电散点图与其心动周期有关，如果心率较慢，不超过窦性心动过速的范围，一般可保持1：1房室传导，心电散点图为稳态吸引子图形。而心率越快则越容易出现房室隐匿传导，出现不规则的心室率，使心电散点图变得复杂。',
    '阵发性室上性心动过速十分常见,在频发室上性早搏的病例中，很多合并有阵发性室上性心动过速,但阵发室上性心动过速很少单独发生，所以心电散点图中既有心动过速的图形也有早搏的图形。',
    '插入性早搏的室性并行心律与插人性室性早搏的图形相似，表现为三点或三个小区域(早搏点、早搏前点、早搏后点)的分布。那么，表现有插入性早搏的室性并行心律的图形则为插人性室早和一般室性并行心律的倒“Y”字图形的组合。',
    "与室性并行心律不同，房性并行心律的P'波需经房室结下传才能引起QRS波的发生。而心电散点图揭示的是RR与RR之间的关系，故P'波下传引起的QRS波与窦性P波下传引起的QRS波互相影响的概率较大，当心率的RR变化不大，而房性并行心律的房性早搏的联律间期变化较大时，其房性并行心律的心电散点图类似于室性并行心律的图形(倒“Y”字形)，早搏点的吸引子图形不完全垂直于45°线，早搏后点的吸引子图形不平行于X轴(与X线夹角一般小于45°)；心率的RR间期变化较大，相对而言，房性并行心律的房性早搏联律间期变化较小时,房性并行心律的心电散点图与多源室性早搏的图形有几分相似。",
    '单发性交界性并行心律时，由于交界性早搏的代偿间期与室性并行心律的室性早搏一样具有完全性，故其与单发性室性并行心律的心电散点图具有完全一样的图形。',
    '伴有文氏现象的二度房室传导阻滞为二度I型房室传导阻滞。表现PR间期逐搏延长，最后发生QRS波脱漏。由于逐搏延长的绝对值逐搏减少，故RR间期呈进行性缩短，直至心室波脱落为止。这样就造成了相邻的RR间期较相邻的PP间期变化幅度大，即心率的瞬时变异性增大，导致其吸引子图形的长、短轴之差变小。',
    '不伴有文氏现象的二度房室传导阻滞称为二度U型房室传导阻滞。心电图表现PR间期一般恒定，其时限可正常或延长。QRS波规律或不定时脱漏，因此出现一个与窦性RR间期呈整倍数关系的长RR间期。RR间期的瞬时变异性比I型阻滞的RR间期要小,故形成的吸引子图形长、短轴之差较大。',
    '',
    '',
    '',
    '窦性逸搏由于其RR间期比主导窦性RR间期慢，窦性逸搏的兴奋频率虽然也有一定的变化范围，但不会与主导窦性频率的范围重叠,因此窦性逸搏与其相邻的RR间期形成的吸引子图形与主导窦性心律的RR间期形成的吸引子图形分离，分离的吸引子图形往往平行于45°线,这是因为逸搏的兴奋同样来自于窦房结，自律性的加速度或减速度比较相同之故。作者对大量图例进行观察，发现窦性逸搏往往同时合并窦性早搏存在于主导窦性心律之中，这是通过时间-RR间期心电散点图及逆向技术证实的。',
    '房性逸搏在窦性心动过缓和窦性停搏或传导障碍时发生,因此，一般会在窦性心律的棒球拍图形的远端形成“曲尺”(L形)状吸引子图形。说明房性逸搏的频率可以快于窦律，亦可慢于窦律。',
    '只有当窦性心律、心房心律很慢或停搏时,才会出现交界性逸搏，交界性逸搏RR间期一般比主导节律RR间期长，当交界性逸搏间断以1~2个出现时，表现为“曲尺”状图形，且位于45°线主导节律吸引子图形的远端。当以逸搏心律形式出现时，是在45°线远端形连续逸搏间期的吸引子图形。',
    '其心电散点图或位于45°线上，或为两条平行于X、Y轴的条状直线，且位于坐标系的最远端(因节律慢远离原点)，说明有一节律RR间期恒定，是室性逸搏节律。',
    '心室起搏频率为跟踪的心房频率，由于心房的频率为正常窦房结发放的频率,具有良好的变时效应，故此时的心室起搏心律的吸引子图形与正常窦性心律的棒球拍形吸引子图形相同，只是在心电图上见到QRS波之前有起搏脉冲信号，QRS波宽大的心室起搏模式。',
    '心电散点图类似于窦性心律合并房性早搏或室性早搏的图形，房性早搏联律间期短时，往往联律间期恒定，与室性早搏联律间期恒定的心电散点图图形一致。',
    '人们早就发现有窦性早搏和窦性逸搏的存在，但是通过普通心电图做出诊断往往比较困难。因为在普通心电图上，根据有限的窦性心搏RR间期的差异,很难判定是窦性心律不齐，还是窦性早搏和窦性逸搏。心电散点图是将一段时图内的所有RR间期都用点加以展现出来，窦房结内的不同“团块”兴奋的频率范围不一样，窦性早搏或窦性逸搏以个别的RR间期出现在主导的窦性RR间期之中,主导的RR间期有自身的频率范围。但RR间期变化的加速度或减速度应该是一致的，理论基础是同一组织的兴奋强度一致，但病理下其强度也可不一致,使问题复杂化。典型的窦性早搏与窦性逸搏的心电散点图会形成平行45°线(主导窦律)的条索状图形，且关于45°线对称。',
    '在一份24小时的动态心电数据中，阵发室性心动过速很少单独出现，而是与频发室性早搏相伴随，心电散点图有多重表现，有些图形相互重叠或相互掩埋，需要回放实时心电图进行确定诊断。',
    '只有当窦性心律、心房心律很慢或停搏时,才会出现交界性逸搏，交界性逸搏RR间期一般比主导节律RR间期长，当交界性逸搏间断以12个出现时，表现为“曲尺”状图形，且位于45°线主导节律吸引子图形的远端。当以逸搏心律形式出现时，是在45°线远端形连续逸搏间期的吸引子图形。',
    '不管是二维坐标系中解析几何的《几何画板》的模拟，还是24小时动态心电数据描绘的真实心电散点图，均显示单纯性室性并行心律的特征性为倒“Y”字的图形。',
    '持续性心房颤动的心电散点图呈扇形，扇形近端的边缘与坐标轴之间保持一定距离(房室结功能不应期)，且与坐标轴成夹角，在X轴一侧的斜率多在0.2左右。',
    '持续性传导比例不断变化的心房扑动是格子状有序多分布图形。'],
    Annotation:['*吸引子：目前的观察发现，一个子图代表一个吸引子，反映心律是同一个起源点。',
    '',
    '',
    '',
    '',
    '',
    '上图为1小时的心电散点图，图形分布于45°线远端，呈棒状。横、纵坐标在＞1000ms的位置。下图为相应片段心电图，可见8个RR间期，平均1327ms，变化范围1287~1361ms。相邻两RR间期差值最小为10ms(1360ms与1350ms)，最大为44ms(1331ms与1287ms)，因此即使是心动过缓，相邻的窦律RR间期变化亦不大。',
    '上图为1小时的心电散点图，图形沿45°线分布，横、纵坐标轴的位置在400~700ms之间，相对应的400~600ms之间为心动过速的散点，而在600~700ms之间的散点为正常心率。下图为相应片段心电图,24个RR间期，平均451ms,变化范围443~457ms。相邻两RR间期差值最小为0ms,最大为5ms。可见窦性心律过快时，节律相对较规整',
    '',
    '心电图可见各下壁和左胸导联P波倒置，房率约150次/分，为左房性心动过速，部分伴传出阻滞，心电散点图为多分布，貌似心房扑动的“格子形”，但又不那么规律，在45°线上最近端的吸引子是由1：1房室传导的RR间期形成,依次向远端的稳态吸引子由2：1、3：1固定房室传导的RR间期形成,分布在加速区与减速区的非稳态吸引子图形由不同传导比例变化时的RR间期形成。',
    '本例心电图中不仅有短阵室上性心动过速，还有单发和二联律性室上性早搏及早搏未下传,在其心电散点图和示意说明图中见到图中部有典型的三分布室上性早搏图形，在45°线的近端有室上性心动过速形成的圆形稳态吸引子图形，在45°线的远端有连续房性早搏未下传的长RR间期形成的稳态吸引子图形，在慢加速与慢减速区有单次早搏未下传形成的非稳态吸引子图形，在“早搏前点”图形的远端一个较为疏淡的散点集落,是二联律早搏吸引子的图形.',
    '左上图为1小时的心电散点图，表现为插入性早搏的图形和倒“Y”字图形的组合，两者早搏前点的图形重合;下图为截取的片段心电图可见有插人性早搏与代偿间期完全的早搏性室性并行心律;右上图为左上图的示意说明图，图中近端的两个圆形是插人性早搏的吸引子图形。',
    '上、下两组图为同一患者的图形。上组图为窦性RR间期变化较小(早搏点的Y值变化小)，而房性早搏的联律间期变化较大(早搏点的X值变化大)。下组图示窦性RR间期变化较大(早搏点的Y值变化大)，而房性早搏的联律间期变化相对较小(早搏点的X值变化相对较小)',
    '心电散点图与示意说明图显示,与室性并行心律的倒“Y”字形图形一致。下图为相应的片段心电图，可见有两个提前出现的窄QRS波,其前有P’波,P’R<0.12s(明显短于窦性P波的PR间期)，第二、三导联上为逆向P’波,两个窄QRS波的联律间期相差较大(659ms,563ms),其后代偿间期完全，为交界性并行心律。',
    '上图为1小时的心电散点图，心电散点图呈四分布，沿45°线近端分布有窦律RR间期形成的稳态吸引子图形,45°线远端分布有两个长RR间期形成的稳态吸引子图形；在加速区与减速区的中部有短长、长短RR间期交替时形成的非稳态吸引子图形；下图为相应心电图，可见PR间期逐波延长，最终R波脱漏，如第二个PR间期长于第一个PR间期,第三个P波后R波脱漏。',
    '上图为1小时的心电散点图，为四分布图形:本图与图12-1相似，只是吸引子图形长、短轴之差较大，表明瞬时RR间期变异性小一些。下图为相应片段心电图，可见PR间期较恒定,不定时地发生P波后QRS波脱漏。',
    '心电散点图中，位于45°线中段的是正常窦律的稳态吸引子图形，位于减速区，与Y轴平行的非稳态小吸引子图形，由心电图中第三个RR间期与第四个RR间期形成(前RR是812ms，后RR是1156ms)o由于这两个相邻RR间期之差较大，脱离了45°线，形成了显而易见的独立吸引子图形，但其后的相邻RR间期之差较小(逐渐回落)，形成的吸引子图形大部分重叠在窦律吸引子中，心电散点图中棒球拍图形远端加速区一侧贴靠的散点集落就是由这些RR间期形成的吸引子图形。本图的吸引子形态发生在窦房文氏传导时，但心电散点图中位于减速区的小吸引子图形，反映的不是文氏传导过程，而是文氏之后的长RR间期：这一长RR间期可能是文氏周期本身，但也不能排除是文氏传导后的房性逸搏。',
    '本例心电图显示间歇性一度窦房传导阻滞，正常窦律RR间期约在920~1100ms之间(HR55~65次/分)，发生阻滞的RR间期在1220ms左右(HR47~48次/分)，阻滞有时单次发生，有时连续发生；心电散点图45°线中段分布着细棒状的窦律稳态吸引子图形，慢减速区有阻滞前点的非稳态吸引子图形，慢加速区有阻滞后点的非稳态吸引子图形，45°线远端分布着连续阻滞点的稳态吸引子图形，与本图类似的心电散点图也可见于“窦房结纵向分离”。',
    '1小时的心电散点图呈4分布，在45°线的近端与远端各有一个稳态吸引子图形，后者的坐标值正好是前者的2倍,近端图形为主导窦律，故变异性较大,远端是2：1下传的窦房阻滞，散点较少，故面积小。在图的加速区与减速区各分布一个非稳态吸引子图形,是正常与阻滞RR间期变换的产物。四个吸引子图形呈正方形。心电图可见第3、8个RR间期(1795、1830ms)为其前窦性RR间期的近似2倍。长RR间期之间无未下传的P波，为窦房阻滞造成。二度II型与二度I型窦房阻滞的心电散点图形类似，需要回放实时心电图进行区分。',
    '上图为1小时的心电散点图，位于45°线中段的窦律吸引子图形的两侧，与45°线平行,且比较对称的是窦性逸搏的非稳态吸引子图形。下图为相应的片段心电图，上条904ms与下条904ms为同一节律。A为主导窦律，平均805ms，变化范围38ms(791~829ms)。B为窦性逸搏，平均887ms，变化范围29ms(875~904ms)。C为窦性早搏,平均752ms，变化范围50ms(722~772ms)。因此，3种窦律的频率范围不一样，频率谱不重叠，并且除主导A节律能有连续的搏动外，B、C节律没有2个B或C相连续，先C后A可用窦性早搏的等周期代偿解释，先BBC可用窦性逸搏解释。',
    '上图为1小时的心电散点图,窦性心律的棒球拍形的远端有一个L形的图形。下图为截取的相应的片段心电图,第七个RR间期为U13ms,明显长于其前的六个RR间期(850~869ms),且第一个箭头处的P波与之前P波稍有不同，为房性逸搏;第八个RR间期为966ms，比第七个RR间期短，但仍长于之前的六个RR间期，为第二个房性逸搏(因为温醒，所以RR间期变短)，后三个为窦性心律RR间期。',
    '上图为1小时的心电散点图，位于45°线中段窦律的棒球拍形吸引子图形的远端有一个曲尺状的图形，约在坐标系1390ms处。下图为相应片段心电图，可见有两个1390ms的长RR间期,构成长间期后的一个QRS波前无P波,为交界性节律。',
    '上图为1小时的心电散点图，位于45°线上的远端有一个由缓慢窦律形成的小的稳态吸引子图形，在加速区与减速区的远端各有一条直线平行于X和Y轴的直线段，位于1300-1800ms的坐标之间，有一节律相对恒定，为1780~1800ms。下图为相应的片段心电图，九个R波，其中第1、2、4、5、6、8、9个为室性逸搏或逸搏心律，RR间期(逸搏间期)为1781、1789ms；第3、7个为窦性夺获;RR间期变短,分别为1328、1335ms',
    '上图为1小时的心电散点图，与正常窦性心律吸引子的图形类似。下图为相应片段心电图,P波有规律发生，间期不等，P波之后由跟踪的起搏脉冲引起QRS波，其RR间期不等，为心房感知、心室起搏心律',
    '上图为1小时的心电散点图和示意说明图，与正常窦性心律合并房性早搏或室性早搏的心电散点图类似。下图为相应片段心电图，箭头所示为房性早搏，房性早搏之后约200ms，有一次起搏脉冲引起的心室搏动，类似于室性早搏(QRS波为室性)',
    '左上图为1小时的散点图，彼此平行的五条“直线”，其中两两关于中间一条对称，均为细短的棒状。下图为相应片段心电图，为便于分析整体节律的变化趋势,截取了两段心电图，共14so心电图可见有A、B、C三种RR间期的变化及分布特征(上条心电图865ms与下条心电图865ms为同一节律)。A为主导窦性节律,RR间期接近，14个A分别为801、799、801、806、812、793、802、795、798、795、797、801、805、810ms，平均801ms，在793~812之间波动，最大变化值19msoB节律为短节律(窦性早搏)，有5个，间期分别为735、734、731、727、730ms，平均为731ms，727~735ms之间波动，最大变化值8ms。C节律为长节律(窦性逸搏)，有6个，间期分别为871.876、857、865、866、864ms,平均867ms，变化范围为857~871ms，最大变化值14ms。',
    '心电图显示既有阵发室性心动过速也有联律间期不等的多源性室性早搏,在心电散点图中只显示了多源性室性早搏的四分布图形，但将连续快速出现的短RR序列单独提取出来，做成上中图，见到分布在45°线近端的疏散的散点集落，不同于窦律的棒球拍形，通过回放实时心电图，确诊是反复出现的短阵室性心动过速，整个情况示意说明图显示得很清楚。',
    '上图为1小时的心电散点图，房颤的扇形远端的边缘被“曲尺”切成平直状，且对称于45°线，坐标值在650~1400ms之间。下图为相应片段心电图，长RR间期1373ms(第一个RR间期)、1364ms(第八个RR间期)为逸搏间期，此长RR间期的后一个R波为交界性逸搏。',
    '左上图为1小时的心电散点图，为倒“Y”字图形；下图为截取的片段心电图可见室性并行心律;左上图的示意说明图，图中的每个子图分别是由正常窦性心律、早搏前点、早搏点、早搏后点形成的吸引子图形。',
    '','']
  }
